import React, { Component } from "react";
import { G } from "../globals";
import U from "../utils";
import Swiper from "swiper";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import "../css/swiper-bundle.css";
import GalleryFull from "./galleryFull";

class Overview extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);
  }

  componentDidMount() {
    this.initSwiperGalleries();
    document.addEventListener("camDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("camDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
    this.removeSwiperGalleries();
  }

  initSwiperGalleries() {
    this.extGallerySwiper = new Swiper(".swiper-ext-gal", {
      slidesPerView: 1.25,
      spaceBetween: U.GetResponsiveMode().mode === "desktop" ? 30 : 10,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
      centeredSlides: true,
      slideActiveClass: "swiper-active-class",
      modules: [Pagination],
      pagination: {
        el: ".ext-gal-thumbs",
        bulletElement: "li",
        bulletClass: "gallery-thumb",
        bulletActiveClass: "gallery-thumb-active",
        clickable: true,
      },
    });

    this.occGallerySwiper = new Swiper(".swiper-occ-gal", {
      slidesPerView: 1.25,
      spaceBetween: U.GetResponsiveMode() === "desktop" ? 30 : 10,
      loop: true,
      autoplay: true,
      centeredSlides: true,
      slideActiveClass: "swiper-active-class",
      modules: [Pagination, Navigation, Autoplay],
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
      pagination: {
        el: ".occ-gal-thumbs",
        bulletElement: "li",
        bulletClass: "gallery-thumb",
        bulletActiveClass: "gallery-thumb-active",
        clickable: true,
      },
      navigation: {
        enabled: true,
        nextEl: ".occ-next-button",
        prevEl: ".occ-prev-button",
      },
    });

    this.mainGallerySwiper = new Swiper(".swiper-main-gallery", {
      loop: true,
      modules: [Pagination, Navigation],
      pagination: {
        el: ".landing-gallery-thumbs",
        bulletElement: "li",
        bulletClass: "gallery-thumb",
        bulletActiveClass: "gallery-thumb-active",
        clickable: true,
      },
      navigation: {
        enabled: true,
        nextEl: ".gallery-main-next",
        prevEl: ".gallery-main-prev",
      },
    });
  }

  removeSwiperGalleries() {
    this.extGallerySwiper.destroy();
    this.occGallerySwiper.destroy();
    this.mainGallerySwiper.destroy();
  }

  introAnim(e) {
    if (!e.detail.state || e.detail.state.name !== "OVERVIEW") return;

    setTimeout(() => {
      const slideDiv = document.querySelector(".slide");
      this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
      // this.slidePopout = document.querySelector(".thumb-gallery");
      setTimeout(() => {
        this.videoSlide.classList.add("swipe-reveal");
        // this.slideBg.classList.add("swipe-reveal");
        this.videoPreroll.play();
        this.fadeDivs.map((f) => f.classList.remove("fade"));
      }, 200);
    }, 100);
  }

  outroAnim(e) {
    if (e.detail.newState && e.detail.newState.name === "OVERVIEW") return;
    const swipeOuts = [...document.querySelectorAll(".swipe-init")];
    const fadeOuts = [...document.querySelectorAll(".fade-init")];
    swipeOuts.map((s) => {
      s.classList.add("swipe-out");
    });
    fadeOuts.map((f) => {
      f.classList.add("fade");
    });
  }

  render() {
    return (
      <div className="slide full-width-slide landing-page-slide clickable">
        <div
          ref={(ref) => (this.videoSlide = ref)}
          className="landing-page-intro-section"
        >
          <div className="video-intro-slide-bg">
            <video
              className="intro-video-preroll"
              ref={(ref) => (this.videoPreroll = ref)}
              loop
              playsInline
              muted
            >
              <source src="./video/fifty_intro_preroll.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="landing-page-intro-text fade-init">
            <h1 className="intro-header-text">
              The new best-in-class workspace that offers exceptional quality{" "}
              <br />
              and connectivity.
            </h1>
            <div
              className="landing-page-play-button"
              onClick={() =>
                G.showOverlay(
                  "showVideo",
                  "https://vimeo.com/669846721/799c4757ba"
                )
              }
            >
              <div className="video-play-button"></div>
              <div className="play-button-label">Play video</div>
            </div>
            <div className="overview-notice">
              Now majority let with only 18,193 sq ft remaining
            </div>
          </div>
          <div className="lp-arrow"></div>
        </div>
        <div className="landing-page-content-section">
          <div className="lp-margin-content-block">
            <h2 className="landing-page-h2">
              <span className="red">Fifty Paddington</span> is a new,
              best-in-class mixed use commercial building situated at the heart
              of Paddington. Located directly opposite the new Elizabeth Line
              entrance, this is the West End’s most accessible location and is
              within minutes from the acres of green space at Hyde Park.
            </h2>
          </div>
          <div className="swiper-ext-gal swiper">
            <div className="swiper-wrapper-ext-gal swiper-wrapper">
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/ext1.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/ext2.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/ext3.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/ext4.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/ext8.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/am1.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/am2.jpg"></img>
              </div>
              <div className="swiper-slide-ext-gal swiper-slide">
                <img src="./images/gallery/am3.jpg"></img>
              </div>
            </div>
            <div className="swiper-thumb-container">
              <ul
                role="list"
                className="gallery-thumb-list ext-gal-thumbs w-list-unstyled"
              ></ul>
            </div>
          </div>
          <div className="lp-margin-content-block">
            <h2 className="landing-page-h2">
              <span className="red">Fifty Paddington</span> is your opportunity
              to be located amongst <br />
              the rich mix of leisure, retail and global office occupiers.
            </h2>
          </div>
          <div className="occ-gal-wrapper">
            <div className="occ-nav-button occ-prev-button"></div>
            <div className="swiper-occ-gal swiper">
              <div className="swiper-wrapper-occ-gal swiper-wrapper">
                <div className="swiper-slide-occ-gal heightFix swiper-slide">
                  <img src="./images/occ/oracle.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/isn.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/efl.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/stjames.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/ultimate.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/treelogy.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/vapiano.jpg"></img>
                </div>
                <div className="swiper-slide-occ-gal swiper-slide">
                  <img src="./images/occ/cerner.jpg"></img>
                </div>
              </div>
              <div className="swiper-thumb-container">
                <ul
                  role="list"
                  className="gallery-thumb-list occ-gal-thumbs w-list-unstyled"
                ></ul>
              </div>
            </div>
            <div className="occ-nav-button occ-next-button"></div>
          </div>

          <div className="lp-margin-content-block">
            <div className="gallery-viewer landing-gallery swiper-main-gallery swiper">
              <div className="swiper-main-wrapper swiper-wrapper">
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/fit1a.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sec1.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sec2.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sec3.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/thr1.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img1.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img2.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img3.jpg"></img>
                </div>
                {/* <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img4.jpg"></img>
                </div> */}
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img5.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img6.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img7.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img8.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/new-img9.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sh1.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sh2.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/sh3.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/ter1.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/ter2.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/ter3.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/ter4.jpg"></img>
                </div>
                <div className="swiper-main-slide swiper-slide">
                  <img src="./images/gallery/ter5.jpg"></img>
                </div>
              </div>
              <div className="gallery-controls landing-galllery-controls">
                <ul
                  role="list"
                  className="gallery-thumb-list landing-gallery-thumbs w-list-unstyled"
                >
                  {/*<li className="gallery-thumb gallery-thumb-active"></li>
                  <li className="gallery-thumb"></li>
            <li className="gallery-thumb"></li>*/}
                </ul>
                <div className="gallery-control-button gallery-main-prev gallery-prev"></div>
                <div className="gallery-control-button gallery-main-next gallery-next"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-page-window pass-through">
          <div className="lp-window-spacer pass-through"></div>
          <div className="lp-window-panel">
            <div className="lp-avail-block">
              <h2 className="landing-page-h2">Availability</h2>
              <ul role="list" className="lp-avail-list w-list-unstyled">
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("SIXTH")}
                >
                  <div className="lp-avail-text">Sixth</div>
                  <div className="lp-avail-text right-align">ISN</div>
                </li>
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("FIFTH")}
                >
                  <div className="lp-avail-text">Fifth</div>
                  <div className="lp-avail-text right-align">
                    St James’s Place
                  </div>
                </li>
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("FOURTH")}
                >
                  <div className="lp-avail-text">Fourth</div>
                  <div className="lp-avail-text right-align">
                    EFL / Nexfibre
                  </div>
                </li>
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("THIRD")}
                >
                  <div className="lp-avail-text">Third</div>
                  <div className="lp-avail-text right-align">Shionogi</div>
                </li>
                <li
                  className="lp-avail-item"
                  onClick={() => this.props.GoToState("SECOND")}
                >
                  <div className="lp-avail-text">Second</div>
                  <div className="lp-avail-text right-align red">
                    5,165/8,557/14,139<span className="sub"> sq ft</span>
                  </div>
                </li>
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("FIRST")}
                >
                  <div className="lp-avail-text">First</div>
                  <div className="lp-avail-text right-align">Oracle</div>
                </li>
                <li
                  className="lp-avail-item unavailable"
                  onClick={() => this.props.GoToState("GROUND")}
                >
                  <div className="lp-avail-text">Ground</div>
                  <div className="lp-avail-text right-align">
                    Under Offer
                    {/* <span className="sub"> sq ft</span> */}
                  </div>
                </li>
              </ul>
              <div className="avail-notice">
                Click on the floor above to view it
              </div>
            </div>
            <div
              className="lp-window-button"
              onClick={() => this.props.GoToState("BUILDING")}
            >
              explore building
            </div>
            <div
              className="lp-window-button"
              onClick={() => this.props.GoToState("LOCATION")}
            >
              view location &amp; amenities
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
